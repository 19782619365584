import { MachineNotFoundError } from '@internal/utils/errors/machineErrors'
import { Machine } from '@internal/utils/machine/Machine'
import { SearchPayload } from '@internal/utils/search/SearchPayload'
import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import axios, { AxiosError, AxiosRequestHeaders } from 'axios'
import { environment } from 'environment/environment'
import { getAxiosData } from 'utils/api/getAxiosData'
import { TSearchResult } from './TSearchResult'

const apiClient = axios.create({
  baseURL: '',
  timeout: 30000,
})

export type MachineMeta = Pick<
  Machine,
  'machineGroupId' | 'brand' | 'name' | 'machineType' | 'model' | 'pdp'
> & {
  mappedModel: string | null
  baseModel: string | null
  rawModel: string | null
}

export const getMachineMeta = async ({
  machineGroupId,
  ip,
  resolveMerged,
}: {
  machineGroupId: string
  resolveMerged?: boolean
  ip?: string
}) => {
  const headers: AxiosRequestHeaders = {}
  if (ip) {
    headers['x-forwarded-for'] = ip
  }
  return apiClient
    .get<MachineMeta>(
      `${
        environment.OWN_URL
      }/api/getMachineMeta/?machineGroupId=${machineGroupId}${
        resolveMerged ? '&resolveMerged=true' : ''
      }`,
      {
        headers,
      }
    )
    .then(getAxiosData)
    .catch((err) => {
      const axiosError = err as AxiosError
      if (axiosError.isAxiosError && axiosError.response?.status === 404) {
        throw new MachineNotFoundError(machineGroupId)
      }
      throw err
    })
}

export const getSerpUrlForAlternatives = async (params: {
  noCache?: boolean
  refreshCache?: boolean
  locale: TLocale
  machine: MachineMeta
  ip?: string
}) =>
  apiClient
    .post<{ url: string }>(`/api/getSerpUrlForAlternatives/`, params)
    .then(getAxiosData)

export const searchMachines = async ({
  search,
  abortController,
  userCountry,
  ip,
  noCache,
  refreshCache,
}: {
  search: SearchPayload
  abortController?: AbortController
  userCountry: string | null
  ip: string | null
  noCache: boolean
  refreshCache: boolean
}) =>
  apiClient
    .post<TSearchResult>(
      `/api/searchMachines/`,
      {
        search,
        userCountry,
        ip,
        disableCache: !!noCache,
        forceUpdateCache: !!refreshCache,
      },
      {
        signal: abortController?.signal,
      }
    )
    .then(getAxiosData)
